<style lang="scss">
.druckstrecke {
  .tabelle-box {
    .col-12 {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
}
</style>

<template>
  <div class="druckstrecke content container">
    <h1>Druckstrecke</h1>
    <p class="intro-txt">Mitten drin, statt nur dabei! Sichern Sie sich maximale Aufmerksamkeit in einem glaubwürdigen Umfeld. Ihre Werbebotschaft wird großflächig und auffällig als Bestandteil der Kleinen Zeitung dargestellt.</p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/druckstrecke.jpg" alt="Druckstrecke" class="img-fluid" />
    </div>

    <h2>Ihre Vorteile</h2>
    <ul class="content-ul">
      <li>kürzere Vorlaufzeiten</li>
      <li>keine Beilegekosten</li>
      <li>Möglichkeit der vollständigen Gestaltung der Druckstrecke: Layout, Textierung, Korrektorat, Korrekturabzugs- und Freigabeabläufe, Sichtung und Auswahl bereitgestellter Bilder, manuelle Aufbereitung und farbliche Optimierung</li>
    </ul>

    <h2>Ihre Werbemöglichkeiten</h2>
    <!--<div class="row image-box justify-content-center align-self-center">
      <div class="col-lg-8 ">
        <img src="@/assets/img/img-content/beilagensonderwerbeformen/druckstrecke-v1.png" alt="Druckstrecke" class="img-fluid" />
      </div>
    </div>-->
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Steiermark
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left"></div>
            <div class="col-lg-4 text-align-right">Di-Fr</div>
            <div class="col-lg-4 text-align-right">Sa-So</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">8 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">32.325,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">33.748,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">12 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">35.398,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">36.956,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">16 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">38.472,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">40.165,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Kärnten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left"></div>
            <div class="col-lg-4 text-align-right">Di-Fr</div>
            <div class="col-lg-4 text-align-right">Sa-So</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">8 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">15.845,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">16.543,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">12 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">17.542,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">18.314,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">16 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">19.237,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">20.084,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            KOMBI
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left"></div>
            <div class="col-lg-4 text-align-right">Di-Fr</div>
            <div class="col-lg-4 text-align-right">Sa-So</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">8 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">47.068,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">49.798,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">12 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">51.728,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">54.729,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">16 Seiten</div>
            <div class="col-6 text-align-left visible-small">Di-Fr</div>
            <div class="col-lg-4 col-6 text-align-right">56.390,00</div>
            <div class="col-6 text-align-left visible-small">Sa-So</div>
            <div class="col-lg-4 col-6 text-align-right">59.661,00</div>
          </div>
        </div>
      </div>
    </div>

    <h2>Überdruck Druckstrecke</h2>
    <p>Überdruck einer Druckstrecke ist auf Anfrage möglich.</p>
    <div class="row justify-content-center align-self-center">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            KOSTEN ÜBERDRUCK
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left">Stück</div>
            <div class="col-lg-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-6 col-6 text-align-left">bis 500 Stück</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">kostenlos</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-6 col-6 text-align-left">bis 3.000 Stück</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">350,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Stück</div>
            <div class="col-lg-6 col-6 text-align-left justify-content-center align-self-center">über 3.000 Stück</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">Verrechnung als Vordruck gemäß Druckkostenkatalog</div>
          </div>
        </div>
      </div>
    </div>

    <div class="info-txt-preis">
      Alle Preise verstehen sich in Euro inklusive Druckkosten, exklusive PR-Produktionskosten, 5% Werbeabgabe und 20% Umsatzsteuer.<br />
      Die Kleine Zeitung behält sich ein Schieberecht von zwei Tagen vor, sollte es aus produktionstechnischen Gründen notwendig sein. Bei Kosten für den Überdruck keine Rabattierung möglich.
    </div>
    <br /><br />
    <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
    <div class="row umsetzungs-bsp">
      <div class="col-lg-6 col-md-6">
        <a href="../../pdf/beilagen/01-12-WS-Journal.pdf" target="_blank">
          <img src="@/assets/img/img-content/beilagensonderwerbeformen/ws-journal-cover.png" alt="WS Journal" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-6 col-md-6">
        <a href="../../pdf/beilagen/01-12-Timberra.pdf" target="_blank">
          <img src="@/assets/img/img-content/beilagensonderwerbeformen/timberra-cover.png" alt="Timberra" class="img-fluid" />
        </a>
      </div>
    </div>
    <!-- -->

    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/beilagen/Druckstrecke.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarife Druckstrecke
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>350 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';

export default defineComponent({
  name: 'Druckstrecke',
  components: {
    //Modal,
  },
});
</script>
